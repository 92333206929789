<template>
  <n-space vertical>
    <n-layout>
      <n-layout-header :inverted="inverted" bordered style="height: 40px">
        <div class="avtar-wrap">
          <n-avatar
            round
            size="medium"
            :src="userInfo?.avatar"
          />
        </div>
      </n-layout-header>
      <n-layout has-sider>
        <n-layout-sider
          bordered
          show-trigger
          collapse-mode="width"
          :collapsed-width="64"
          :width="240"
          :native-scrollbar="false"
          :inverted="inverted"
          style="height: calc(100vh - 63px)"
        >
          <n-menu
            :default-value="currentPath"
            :collapsed-width="64"
            :collapsed-icon-size="22"
            :options="menuOptions"
          />
        </n-layout-sider>
        <n-layout style="height: calc(100vh - 80px)" :native-scrollbar="false">
          <slot />
        </n-layout>
      </n-layout>
      <n-layout-footer :inverted="inverted" bordered>
        <div class="footer">
          Tduck Cms
        </div>
      </n-layout-footer>
    </n-layout>
  </n-space>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useMenuStore } from '~/composables/menuStore'
import { useUser } from '~/composables/useAuth'

const menuStore = useMenuStore()

const userInfo = useUser()

const menuOptions = menuStore.menus

const inverted = ref(false)

const route = useRoute()

const currentPath = ref(route.path)

console.log(route.path)
</script>
<style scoped lang="scss">
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.avtar-wrap {
  float: right;
  margin-right: 30px;
}
</style>
