import { defineStore } from 'pinia'
import { AllApplication } from '@icon-park/vue-next'
import { h } from 'vue'
import { NIcon } from 'naive-ui'
import { NuxtLink } from '#components'

function renderIcon (icon: Component) {
  return () => h(NIcon, null, { default: () => h(icon) })
}

const allMenus:any = [
  {
    label: () =>
      h(
        NuxtLink,
        {
          to: {
            path: '/admin/document/category'
          }
        },
        { default: () => '文档分类' }
      ),
    key: '/admin/document/category',
    icon: renderIcon(AllApplication)
  },
  {
    label: () =>
      h(
        NuxtLink,
        {
          to: {
            path: '/admin/document/nav'
          }
        },
        { default: () => '文档导航' }
      ),
    key: '/admin/document/nav',
    icon: renderIcon(AllApplication)
  }
]

export const useMenuStore = defineStore('menu', () => {
  const menus = allMenus

  return {
    menus
  }
})
